<template>
  <c-box 
    id="kuisioner"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Formulir Gizi',
            href: '/',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <Steps
        v-chakra
        :current="4"
        :total="6"
        mt="30px"
      />

      <c-box
        w="100%"
        max-width="590px"
        mx="auto"
        margin-top="50px"
      >
        <c-box text-align="center">
          <c-text
            color="primary.400"
            font-family="roboto"
            text-transform="uppercase"
            font-weight="500"
            :font-size="['16px', '18px']"
            margin-bottom="10px"
          >
            Formulir Gizi (4/6)
          </c-text>
          <c-heading
            font-weight="700"
            :font-size="['18px', '28px']"
            :line-height="['27px', '42px']"
            color="black.900"
          >
            Data Aktivitas Harian
          </c-heading>
        </c-box>
        <form @submit.prevent="submit">
          <c-box
            v-chakra="{
              'input[type=radio]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              'input[type=checkbox]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              div: {
                fontSize: ['14px', '18px'],
              },
            }"
            margin-top="24px"
          >
            <!-- <c-form-control 
              :is-disabled="!isEditable"
              :is-required="true"
              :is-invalid="$v.sportType.$invalid"
              :mb="['16px', '24px']"
            >
              <c-form-label
                :fontSize="['14px', '16px']"
                :color="sportType_.length > 0 ? '#008C81' : '#555'"
                fontWeight="400"
                fontFamily="Roboto"
              >
                Selama 1 minggu terakhir, pilih semua jenis aktivitas atau olahraga yang sudah kamu lakukan dan durasinya
              </c-form-label>
              <c-box
                as="p"
                :fontSize="['14px', '16px']"
                color="#555"
                fontWeight="400"
                fontFamily="Roboto"
                mb="8px"
              >
                Jenis olahraga yang dilakukan
              </c-box>
              <c-stack>
                <c-checkbox-group
                  variant-color="primary"
                  :is-disabled="isDisabled"
                  size="lg"
                  :fontSize="['14px', '16px']"
                  fontWeight="400"
                  color="#111"
                  spacing="2"
                  v-model="sportType_"
                  required
                >
                  <template v-for="item in SPORT_TYPE">
                    <c-checkbox
                      :key="item.value"
                      :value="item.value"
                      :is-disabled="
                        sportType_.includes('60') &&
                          item.value !== '60'
                      "
                      :color="isIncludesValue(item.value) ? '#008C81' : '#555'"
                      :fontWeight="isIncludesValue(item.value) ? '500' : '400'"
                    >
                      {{ item.label }}
                    </c-checkbox>
                    <c-form-control
                      :key="`custom-type-${item.value}`"
                      v-if="item.value == '68' && isIncludesValue('68')"
                      :is-invalid="checkInvalidChekbox(item.value)"
                      ml="28px"
                    >
                      <c-input
                        type="text"
                        :height="['48px', '62px']"
                        :fontSize="['14px', '18px']"
                        paddingRight="70px"
                        :color="customSport ? '#008C81' : '#555'"
                        :fontWeight="customSport ? '500' : '400'"
                        placeholder="Masukkan jenis olahraga lainnya"
                        v-model="customSport"
                        mb="8px"
                      />
                    </c-form-control>
                    <c-form-control
                      :key="`duration-${item.value}`"
                      v-if="
                        sportType_.includes(item.value) &&
                          item.value !== '60'
                      "
                      :is-invalid="checkInvalidChekbox(item.value)"
                      ml="28px"
                    >
                      <c-select
                        :height="['48px', '62px']"
                        :fontSize="['14px', '18px']"
                        placeholder="Durasi"
                        :color="durations[item.value] ? '#008C81' : '#555'"
                        :fontWeight="durations[item.value] ? '500' : '400'"
                        v-model="durations[item.value]"
                        required
                      >
                        <option
                          v-for="option in SPORT_DURATION"
                          :key="option.value"
                          :value="option.value"
                        >
                          {{ option.label }}
                        </option>
                      </c-select>
                    </c-form-control>
                  </template>
                </c-checkbox-group>
                <c-form-helper-text
                  v-if="!$v.sportType.required"
                  fontSize="12px"
                  color="red.500"
                >
                  Jenis olahraga lainnya harus diisi
                </c-form-helper-text>
                <template v-for="(value, key) in $v.sportType.$each.$iter">
                  <c-form-helper-text
                    :key="`validate-duration-${key}`"
                    v-if="!value.duration.required"
                    fontSize="12px"
                    color="red.500"
                  >
                    Durasi dari olahraga {{ value.$model.type }} harus diisi
                  </c-form-helper-text>
                  <c-form-helper-text
                    :key="`validate-type-${key}`"
                    v-if="!value.customType.required"
                    fontSize="12px"
                    color="red.500"
                  >
                    Jenis olahraga harus diisi
                  </c-form-helper-text>
                </template>
              </c-stack>
            </c-form-control> -->

            <FormCheckboxSport
              v-model="sportType_"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.sportType.$invalid"
              :v="$v"
              label="Selama 1 minggu terakhir, pilih semua jenis aktivitas atau olahraga yang sudah kamu lakukan dan durasinya"
              sub-label="Jenis olahraga yang dilakukan"
              :options="SPORT_TYPE"
              disabled-option="60"
              :is-other="true"
              other-option="68"
              :options-durations="SPORT_DURATION"
              :durations="durations"
              :custom-sport="customSport"
              @updateDurations="updateDurations"
              @updateCustomSport="updateCustomSport"
            />

            <FormSelect 
              v-model="weekdaysTypicalActivity"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.weekdaysTypicalActivity.$invalid"
              label="Tipikal keaktifan kamu pada hari kerja (weekdays)"
              placeholder="Pilih tipikal"
              :options="WEEKDAYS_TYPICAL_ACTIVITY"
            />

            <FormSelect 
              v-model="weekendTypicalActivity"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.weekendTypicalActivity.$invalid"
              label="Tipikal keaktifan kamu pada hari libur dan akhir pekan"
              placeholder="Pilih tipikal"
              :options="WEEKEND_TYPICAL_ACTIVITY"
            />

            <FormRadio
              v-model="reducePhysicalActivity"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.reducePhysicalActivity.$invalid"
              label="Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?"
              :options="LIMITATION_ACTIVITY"        
            />

            <!-- <FormRadio
              v-model="physicalLimitationActivity"
              :isDisabled="!isEditable"
              isRequired
              :isInvalid="$v.physicalLimitationActivity.$invalid"
              label="Apakah kamu memiliki keterbatasan berikut untuk melakukan aktivitas fisik?"
              :options="PHYSICAL_ACTIVITY"        
            /> -->

            <FormCheckbox
              v-model="physicalLimitationActivity_"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.physicalLimitationActivityStore.$invalid"
              label="Apakah kamu memiliki keterbatasan berikut untuk melakukan aktivitas fisik?"
              :options="PHYSICAL_ACTIVITY"
              disabled-option="85"
              :is-other="true"
              other-option="138"
              :other="physicalLimitationActivityOther"
              @updateOther="updatePhysicalLimitationActivityOther"
            />

            <FormInput 
              v-model="averageSleepHours"
              type="number"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.averageSleepHours.$invalid"
              label="Berapa jam rata-rata durasi tidur per hari"
              placeholder="Masukkan durasi tidur"
              right-element="Jam"
              :invalid-text="$v.averageSleepHours.minValue ? ($v.averageSleepHours.maxValue ? '' : 'Jam tidur tidak boleh lebih dari 24 jam') : 'Jam tidur tidak boleh kurang dari 0 jam'"
            />

            <c-form-control
              id="bedtime"
              :is-disabled="!isEditable"
              :is-required="true"
              :is-invalid="$v.bedTime.$invalid"
              :mb="['16px', '24px']"
            >
              <c-form-label
                :font-size="['14px', '16px']"
                :color="value ? '#008C81' : '#555'"
                font-weight="400"
                font-family="Roboto"
              >
                Jam berapa biasa kamu tidur?
              </c-form-label>
              <vue-timepicker 
                v-model="bedTime"
                v-chakra="{
                  'input.display-time': {
                    p: '1rem !important',
                    height: ['48px !important', '62px !important'],
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    borderRadius: '4px',
                    border: '1px solid #CBD5E0',
                  }
                }"
                format="HH:mm"
                :minute-interval="1" 
                hour-label="Jam"
                minute-label="Menit"
                input-width="100%"
                :close-on-complete="false"
                :hide-clear-button="false" 
                drop-direction="up"
                container-id="bedtime"
                :disabled="!isEditable"
                :input-class="['skip-error-style', $v.bedTime.$invalid ? 'invalid' : '']"
                :font-size="['14px', '18px']"
                :color="bedTime ? '#008C81' : '#555'"
              />
              <c-form-helper-text
                v-if="!$v.bedTime.required"
                font-size="12px"
                color="red.500"
              >
                Harus diisi
              </c-form-helper-text>
              <c-form-helper-text
                v-if="!$v.bedTime.format"
                font-size="12px"
                color="red.500"
              >
                Format waktu tidak benar (contoh: 21:30)
              </c-form-helper-text>
            </c-form-control>

            <c-grid
              margin-top="16px"
              template-columns="repeat(2, 1fr)" 
              gap="3"
            >
              <c-button
                w="100%"
                background-color="white"
                color="primary.400"
                border-color="primary.400"
                border-radius="100px"
                variant="outline"
                px="30px"
                h="48px"
                :is-disabled="isSubmitting || isBack"
                @click="onPrevious"
              >
                Sebelumnya
              </c-button>
              <c-button
                w="100%"
                background-color="primary.400"
                color="white"
                border-radius="100px"
                variant="solid"
                px="30px"
                :is-disabled="isDisabled || isBack"
                :is-loading="isSubmitting"
                loading-text="Submitting"
                h="48px"
                type="submit"
              >
                Selanjutnya
              </c-button>
            </c-grid>
          </c-box>
        </form>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { decimal, required, requiredIf, maxValue, minValue } from 'vuelidate/lib/validators'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import Steps from '@/components/steps'
import FormSelect from '@/views/client/kuisioner/forms/form-select'
import FormInput from '@/views/client/kuisioner/forms/form-input'
import FormRadio from '@/views/client/kuisioner/forms/form-radio'
import FormCheckbox from '@/views/client/kuisioner/forms/form-checkbox'
import FormCheckboxSport from '@/views/client/kuisioner/forms/form-checkbox-sport'
import { SPORT_TYPE, SPORT_DURATION, WEEKDAYS_TYPICAL_ACTIVITY, WEEKEND_TYPICAL_ACTIVITY, PHYSICAL_ACTIVITY, LIMITATION_ACTIVITY } from '@/constants/questionnaire'
import VueTimepicker from 'vue2-timepicker'
import _ from 'lodash'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'FormulirGizi4Page',
  components: {
    BreadcrumbPath,
    Steps,
    FormSelect,
    FormInput,
    FormRadio,
    FormCheckbox,
    VueTimepicker,
    FormCheckboxSport,
  },
  data() {
    return {
      sportType: [],
      sportType_: [],
      weekdaysTypicalActivity: null,
      weekendTypicalActivity: null,
      reducePhysicalActivity: null,
      physicalLimitationActivityStore: [],
      physicalLimitationActivity_: [],
      physicalLimitationActivityOther: null,
      averageSleepHours: null,
      bedTime: '',
      durations: {},
      customSport: '',
      isSubmitting: false,
      isBack: false,

      // -- dinamis
      questions: [],

      // options
      SPORT_TYPE,
      SPORT_DURATION,
      WEEKDAYS_TYPICAL_ACTIVITY,
      WEEKEND_TYPICAL_ACTIVITY,
      PHYSICAL_ACTIVITY,
      LIMITATION_ACTIVITY,
    }
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.dailyActivities,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    isDisabled() {
      return this.$v.$invalid
    },
    physicalLimitationActivity() {
      return this.$store.state.kuisioner.dailyActivities?.physicalLimitationActivity
    },
    value() {
      return {
        sportType: this.sportType,
        reducePhysicalActivity: this.reducePhysicalActivity,
        physicalLimitationActivity: this.physicalLimitationActivityStore,
        weekdaysTypicalActivity: this.weekdaysTypicalActivity,
        weekendTypicalActivity: this.weekendTypicalActivity,
        averageSleepHours: this.averageSleepHours,
        bedTime: this.bedTime,
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  validations: {
    reducePhysicalActivity: { required },
    physicalLimitationActivityStore: { 
      required,
      $each: {
        value: { required },
        other: { required: requiredIf((model) => model.value === '138') },
      },
    },
    weekdaysTypicalActivity: { required },
    weekendTypicalActivity: { required },
    averageSleepHours: { required, decimal, maxValue: maxValue(24), minValue: minValue(0) },
    bedTime: {
      required,
      format: (v) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v),
    },
    sportType: {
      required,
      $each: {
        type: { required },
        duration: {
          required: requiredIf((model) => model.type !== '60'),
        },
        customType: {
          required: requiredIf((model) => model.type === '68'),
        },
      },
    },
  },
  watch: {
    sportType: {
      immediate: false,
      handler(val, prev) {
        // if (val?.length == 0) return
        if (JSON.stringify(val) === JSON.stringify(prev)) return
        this.$store.commit('kuisioner/setSportType', val)
      },
    },
    sportType_: {
      immediate: false,
      deep: true,
      handler(val) {
        if (val.length == 0) {
          this.sportType = []
          return
        }

        if (val.includes('60')) {
          this.customSport = null
          this.durations = {}
          this.sportType = [{ 
            type: '60',
            duration: '',
          }]
          return
        }
        
        let data = val.map((it) => {
          if (it == '68') {
            return {
              type: it, 
              duration: this.durations[it],
              customType: this.customSport,
            }
          } else {
            return {
              type: it,
              duration: this.durations[it],
            }
          }
        })
        this.sportType = data
      },
    },
    customSport: {
      immediate: false,
      deep: true,
      handler(val) {
        if (val == null) return
        
        const data = this.sportType_.map((it) => {
          if (it == '68') {
            return {
              type: it,
              duration: this.durations[it],
              customType: val,
            }
          } else {
            return {
              type: it,
              duration: this.durations[it],
            }
          }
        })
        this.sportType = data
      },
    },
    durations: {
      immediate: false,
      deep: true,
      handler(val) {
        if (Object.keys(val)?.length == 0) return
        const data = this.sportType_.map((it) => {
          if (it == '68') {
            return {
              type: it,
              duration: val[it],
              customType: this.customSport,
            }
          } else {
            return {
              type: it,
              duration: val[it],
            }
          }
        })
        this.sportType = data
      },
    },
    weekdaysTypicalActivity: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          this.$store.commit('kuisioner/setWeekdaysTypicalActivity', val)
        }
      }, 500),
      deep: true,
    },
    weekendTypicalActivity: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          this.$store.commit('kuisioner/setWeekendTypicalActivity', val)
        }
      }, 500),
      deep: true,
    },
    reducePhysicalActivity: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          this.$store.commit('kuisioner/setReducePhysicalActivity', val)
        }
      }, 500),
      deep: true,
    },
    physicalLimitationActivity_: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          const physicalLimitationActivity = val?.map((it) => {
            if (it == '138') {
              return {
                value: it,
                other: this.physicalLimitationActivityOther,
              }
            } else if (it) {
              return {
                value: it,
              }
            }
          })
          this.physicalLimitationActivityStore = physicalLimitationActivity
          this.$store.commit('kuisioner/setPhysicalLimitationActivity', physicalLimitationActivity)
        }
      }, 500),
      deep: true,
    },
    physicalLimitationActivityOther: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          const physicalLimitationActivity = this.physicalLimitationActivity_?.map((it) => {
            if (it == '138') {
              return {
                value: it,
                other: val,
              }
            } else if (it) {
              return {
                value: it,
              }
            }
          })
          this.physicalLimitationActivityStore = physicalLimitationActivity
          this.$store.commit('kuisioner/setPhysicalLimitationActivity', physicalLimitationActivity)
        }
      }, 500),
      deep: true,
    },
    averageSleepHours: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          this.$store.commit('kuisioner/setAverageSleepHours', val)
        }
      }, 500),
      deep: true,
    },
    bedTime: {
      handler: _.debounce(function(val) {
        if (!this.isSubmitting && !this.isBack) {
          this.$store.commit('kuisioner/setBedTime', val)
        }
      }, 500),
      deep: true,
    },
  },
  created() {
    if (this.currentStep && this.currentStep != 4) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    }
  },
  async mounted() {
    await this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'dailyActivities',
    })

    this.reducePhysicalActivity = this.kuisData.reducePhysicalActivity
    if (this.kuisData.physicalLimitationActivity && Array.isArray(this.kuisData.physicalLimitationActivity)) {
      this.physicalLimitationActivity_ = this.kuisData.physicalLimitationActivity.map((it) => {
        if (it && PHYSICAL_ACTIVITY.some((el) => el.value == it?.value || el.value == it)) {
          return it?.value
        }
      })
      this.physicalLimitationActivityOther = this.kuisData.physicalLimitationActivity.find((it) => it.value == '138')?.other
    }
    this.weekdaysTypicalActivity = this.kuisData.weekdaysTypicalActivity
    this.weekendTypicalActivity = this.kuisData.weekendTypicalActivity
    this.averageSleepHours = this.kuisData.averageSleepHours
    this.bedTime = this.kuisData.bedTime ?? ''

    if (this.kuisData.sportType) {
      this.sportType_ = this.kuisData.sportType.map((it) => it.type)
      this.durations = this.kuisData.sportType.reduce(
        (acc, it) => ({
          ...acc,
          [it.type]: it.duration,
        }),
        {},
      )
      this.customSport = this.kuisData.sportType.find((it) => it.customType)?.customType
    }
  },
  methods: {
    ...mapMutations({
      setStoreDailyActivities: 'kuisioner/setDailyActivities',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    updateDurations(value) {
      this.durations = value
    },
    updateCustomSport(value) {
      this.customSport = value
    },
    updatePhysicalLimitationActivityOther(value) {
      this.physicalLimitationActivityOther = value
    },
    async submit() {
      try {
        if (this.isEditable) {
          this.$v.$touch()
          if (this.$v.$invalid) return
    
          this.isSubmitting = true
          await this.$store.dispatch('kuisioner/setDailyActivities', {
            programId: this.$route.params.programId,
            isDraft: true,
            value: this.value,
            answers: this.answers,
            step: 5,
          })
        }
        
        await this.$store.dispatch('kuisioner/updateCurrentStep', 5)
        await this.$store.commit('kuisioner/resetDailyActivities')
        this.$router.push({
          name: 'client.kuisioner5',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
        this.isSubmitting = false
      }
    },
    async onPrevious() {
      this.isBack = true
      await this.$store.dispatch('kuisioner/updateCurrentStep', 3)
      await this.$store.commit('kuisioner/resetDailyActivities')
      this.$router.push({
        name: 'client.kuisioner3',
        params: this.$route.params,
      })
    },
    isIncludesValue(value) {
      return this.sportType_.includes(value)
    },
    checkInvalidChekbox(value) {
      const it = Object.values(this.$v.sportType.$each.$iter).find((it) => it.$model.type == value)
      
      if (it) {
        return it.$invalid
      }
      return false
    },
  },
}
</script>

<style scoped>
::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
  bottom: 3.5em !important;
}

::v-deep.vue__time-picker input.invalid {
  box-shadow:  0 0 0 1px #e66673 !important;
  border: 1px solid #e66673 !important;
}

@media (min-width: 768px) {
  ::v-deep.vue__time-picker-dropdown, ::v-deep.vue__time-picker .dropdown {
    bottom: 3.5em !important;
  }
}
</style>
